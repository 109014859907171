import React, { useEffect, useRef, useState } from "react";
import FullCalendar, { EventClickArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { EventInput } from "@fullcalendar/react";
import listPlugin from "@fullcalendar/list";
import Layout from "../../components/global/Layout/Layout";
import { ICalendarProps, IEvent } from "../../@types/ICalendar";
import useMobileDetect from "../../hooks/useMobileDetect";
import { displayTimeFormat, formatDate } from "../../services/dates";
import EventDetailModal from "../../components/common/Modal/EventDetailModal/EventDetailModal";
import { container } from "./Calendar.module.scss";
import { ICalendarCardProps } from "../../components/common/Cards/CalendarCard/CalendarCardProps";
import { PageProps } from "gatsby";

interface ICalendarPageContext {
  events: {
    node: {
      fields: ICalendarCardProps;
    };
  }[];
}

const Calendar = (props: PageProps<null, ICalendarPageContext>) => {
  const [eventDetails, setEventDetails] = useState<ICalendarProps>({
    selectedEvent: null,
    isEventDetailModal: false,
  });
  const calendarRef = useRef(null);
  const [previousLastRow, setPreviousLastRow] = useState(null);
  const isMobile = useMobileDetect();
  const pageContext: ICalendarPageContext = props.pageContext;
  const events: EventInput[] = pageContext?.events.map((event: IEvent) => {
    const timeIndex = event.node.fields.eventDate?.indexOf("T");
    let date = event.node.fields.eventDate;
    if (event.node.fields.isAllDayEvent) {
      date = event.node.fields.eventDate?.substring(0, timeIndex);
    }
    const obj = {
      id: event.node.fields.id,
      title: event.node.fields.title,
      start: date,
      allDay: event.node.fields.isAllDayEvent,
      end: event.node.fields.endDate,
    };
    return obj;
  });

  const hideRowsWithoutDataDate = () => {
    const table = calendarRef.current?.querySelector(
      ".fc-scrollgrid-sync-table"
    );
    if (table) {
      const rows = table.querySelectorAll("tbody tr");
      if (rows.length > 0 && !(rows.length < 5)) {
        if (previousLastRow) {
          previousLastRow.style.display = ""; // Unhide the previous row
        }
        const lastRow = rows[rows.length - 1];
        const hasDataDate = Array.from(lastRow.querySelectorAll("td")).some(
          cell => cell.hasAttribute("data-date")
        );
        setPreviousLastRow(lastRow);
        if (!hasDataDate) {
          lastRow.style.display = "none"; // Hide the row
        }
      } else {
        console.log("No rows found.");
      }
    } else {
      console.log("Table not found.");
    }
  };

  useEffect(() => {
    // Give FullCalendar a moment to render
    setTimeout(hideRowsWithoutDataDate, 3000);
  }, []);

  const handleEventClick = (e: EventClickArg) => {
    const selectedEvent = pageContext?.events.find(
      event => event.node.fields.id === e.event._def.publicId
    );
    const eventObj = {
      title: selectedEvent?.node.fields.title,
      time: `${formatDate(
        new Date(selectedEvent?.node.fields.eventDate),
        displayTimeFormat
      )}- ${formatDate(
        new Date(selectedEvent?.node.fields.endDate),
        displayTimeFormat
      )}`,
      description: selectedEvent?.node.fields.description,
      rlhCallToAction: selectedEvent?.node.fields?.rlhCallToAction,
      eventDate: selectedEvent?.node.fields.eventDate,
      endDate: selectedEvent?.node.fields.endDate,
    };
    setEventDetails({
      ...eventDetails,
      isEventDetailModal: true,
      selectedEvent: eventObj,
    });
  };
  const hideEventDetail = () => {
    setEventDetails({
      ...eventDetails,
      isEventDetailModal: false,
      selectedEvent: null,
    });
  };
  const eventTimeFormat = {
    hour: "numeric",
    minute: "2-digit",
    meridiem: "short",
  };
  return (
    <Layout>
      <div className={container}>
        {eventDetails?.isEventDetailModal && (
          <>
            <EventDetailModal
              isShow={true}
              onHide={hideEventDetail}
              eventDetails={eventDetails.selectedEvent}
            />
          </>
        )}
        <div ref={calendarRef}>
          <FullCalendar
            plugins={[dayGridPlugin, listPlugin]}
            initialView={isMobile ? "listMonth" : "dayGridMonth"}
            editable={true}
            selectable={true}
            eventClick={handleEventClick}
            initialEvents={events}
            eventTimeFormat={eventTimeFormat}
            visibleRange={currentDate => {
              // Get the first and last day of the current month
              const startDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                1
              );
              const endDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0
              );
              return { start: startDate, end: endDate };
            }}
            // eventContent={findLastRow}
            showNonCurrentDates={false} // Hides extra grid cells for dates outside the month
            datesSet={() => hideRowsWithoutDataDate()}
          />
        </div>
      </div>
    </Layout>
  );
};
export default Calendar;
